import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

const MultiSelectDropDown6 = ({
  label,
  filterBy,
  value,
  options,
  onChange,
  onFilter,
  onFocus,
  optionLabel,
  itemTemplate,
  virtualScrollerOptions
}) => {
  return (
    <>
      <span className="p-float-label">
        <span className="af-adv-sreach-label">{label}</span>
        {virtualScrollerOptions ? <MultiSelect
          inputId="multiselect"
          value={value}
          options={options}
          onChange={onChange}
          onFilter={onFilter}
          onFocus={onFocus}
          optionLabel={optionLabel}
          filter
          filterBy={filterBy}
          itemTemplate={itemTemplate}
          virtualScrollerOptions={{
            lazy: virtualScrollerOptions.lazy,
            onLazyLoad: virtualScrollerOptions.onLazyLoad,
            itemSize: virtualScrollerOptions.itemSize,
            showLoader: virtualScrollerOptions.showLoader,
            loading: virtualScrollerOptions.loading,
            delay: virtualScrollerOptions.delay,
            loadingTemplate: (options) => {
              return (
                <div
                  className="p-d-flex p-ai-center p-p-2"
                  style={{ height: "34px" }}>
                  <Skeleton
                    width={options.even ? "70%" : "60%"}
                    height="1.5rem"
                  />
                </div>
              );
            },
          }}
        /> : 
        <MultiSelect
          inputId="multiselect"
          value={value}
          options={options}
          onChange={onChange}
          onFilter={onFilter}
          onFocus={onFocus}
          optionLabel={optionLabel}
          filter
          filterBy={filterBy}
          itemTemplate={itemTemplate}         
        /> }
      </span>
    </>
  );
};

export default MultiSelectDropDown6;
