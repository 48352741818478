import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./FileUpload.scss";
import NestedConfirmationModal from "../DialogModals/NestedConfirmationModal";
import moment from "moment-timezone";
import Spinner from "../Spinner/Spinner";

export const FilePosition = {
  RIGHT: "Right",
  BOTTOM: "Bottom",
};

const FileUpload = ({
  attachments,
  addAttachment,
  attachmentTitleRequired = true,
  disable = false,
  deleteAttachment,
  disableDeleteAttachment = false,
  downloadAttachment,
  fileFormats, //MIME Types
  getIntlMessage,
  maxFileSize = 100000, //KB (100MB)
  position = FilePosition.BOTTOM,
  loading = false,
  readOnly = false,
  tabOrder = 0
}) => {
  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [deleteAttachmentId, setAttachmentId] = useState(0);
  const [fileKey, setFileKey] = useState("");
  const [fileInfo, setFileInfo] = useState(false);
  const [
    showDeleteConfirmationDailog,
    setShowDeleteConfirmationDailog,
  ] = useState(false);
  const [showFileDailog, setShowFileDailog] = useState(false);
  const [showFileFormatDailog, setShowFileFormatDailog] = useState(false);
  const [resetFile, setResetFile] = useState(false);

  useEffect(() => {
    resetFile && resetChooseFile();
  }, [resetFile]);

  const callBackFromDailog = ({ newVal }) => {
    setShowFileDailog(false);
    setFileInfo(false);
    setResetFile(true);
  };

  const callBackDeleteConfirmationDailog = ({ newVal }) => {
    if (newVal) {
      deleteAttachment(deleteAttachmentId);
    } else {
      setAttachmentId(0);
    }
    setShowDeleteConfirmationDailog(false);
  };

  const guid = () => {
    const cryptoAttachmentUID = window.crypto || window.msCrypto;
    return cryptoAttachmentUID && cryptoAttachmentUID.randomUUID();
  };

  const fileContent = (rowData) => {
    return (
      <span
        className={rowData?.attachment_id ? `dynamic_anchor` : ""}
        onClick={(e) => {
          rowData?.attachment_id &&
            downloadAttachment(rowData?.att_dms_source || rowData?.dms_source);
        }}
      >
        {rowData?.att_title || rowData?.title}
      </span>
    );
  };

  const deleteFileIcon = (rowData) => {
    return (
      <span className= {disableDeleteAttachment ? "grid-icons disable-icon" : "grid-icons"} >
        {!readOnly && (
          <i
            className="icon-icon_trash"
            tabindex={tabOrder}
            onClick={ !disableDeleteAttachment ? () => {
              if (rowData?.attachment_id) {
                setAttachmentId(rowData?.attachment_id);
                setShowDeleteConfirmationDailog(true);
              } else {
                deleteAttachment(rowData?.id);
              }
            } : null}
            onKeyPress={ !disableDeleteAttachment ? () => {
              if (rowData?.attachment_id) {
                setAttachmentId(rowData?.attachment_id);
                setShowDeleteConfirmationDailog(true);
              } else {
                deleteAttachment(rowData?.id);
              }
            } : null}
          ></i>
        )}
      </span>
    );
  };

  const onAddAttachment = (e) => {
    if (fileInfo.isSizeValid && fileInfo.isFormatValid) {
      attachmentTitleRequired && setAttachmentTitle("");
      addAttachment(fileInfo.data);
    } else if (!fileInfo.isSizeValid) {
      //Invalid Size
      setShowFileDailog(true);
    } else if (!fileInfo.isFormatValid) {
      //Invalid File Format
      setShowFileFormatDailog(true);
    }
    setResetFile(true);
    setFileInfo(false);
  };

  //on File selection
  const onFilesSelect = (e) => {
    let file;
    var target = e.target || e.srcElement;
    if (target.value.length == 0) {
      //console.log("Suspect Cancel was hit, no files selected.");
      setResetFile(true);
      setFileInfo(false);
    } else {
      //console.log("File selected: ", target.value);
      //console.log(target.files.length);
      file = target.files.length > 0 ? target.files[0] : undefined;
    }
    
    if (file) {
      var _fileInfo = {
        id: guid(),
        filename: file.name,
        contentType: file.name.includes(".msg")
          ? "application/vnd.ms-outlook"
          : file.type,
        size: file.size / 1024,
        file: file, //file.name.includes('.msg') ? reader.result.replace("application/octet-stream", "application/vnd.ms-outlook") : formData,
        // created_on: moment(new Date()).format("MM/DD/YYYY"),
        created_on: currentStartDate(localStorage.getItem("userTimezone")),
        title: attachmentTitle || file.name || "",
      };
      setFileInfo({
        data: _fileInfo,
        isSizeValid: _fileInfo.size < maxFileSize,
        isFormatValid: fileFormats.some(
          (format) => format === _fileInfo.contentType
        ),
      });
    }
  };

  //Clear choosed File
  const resetChooseFile = () => {
    let randomString = Math.random().toString(36);
    setFileKey(randomString);
    setResetFile(false);
  };

  const currentStartDate = (timezone) => {
    let startDate = moment.tz(moment(), timezone).format("MM/DD/YYYY");
    return startDate;
  }

  return (
    <div className="fileupload-container">
      {!readOnly ? (
        <>
          {attachmentTitleRequired && (
            <div className="p-sm-12 p-md-12 p-lg-12">
              <div className="labels-padding">
                <label>{getIntlMessage("CM_LABEL_ATTACHMENT_TITLE")}</label>
              </div>
              <div>
                <InputText
                  autoComplete="off"
                  className="fileupload_input"
                  type="text"
                  placeholder=""
                  value={attachmentTitle}
                  onChange={(event) => {
                    setAttachmentTitle(event.target.value);
                  }}
                />
              </div>
            </div>
          )}

          <div
            className={`${(position === FilePosition.RIGHT && "p-grid") || ""}`}
          >
            <div
              className={`p-sm-12 p-md-12 ${
                position === FilePosition.RIGHT ? "p-lg-6" : "p-lg-12"
              }`}
            >
              <div className="labels-padding ">
                <label>{getIntlMessage("CM_LABEL_ATTACHMENT_FILE_NAME")}</label>
              </div>
              <InputText
                className="fileupload_input"
                type="file"
                onChange={(e) => onFilesSelect(e)}
                key={fileKey || ""}
                accept={fileFormats.toLocaleString()}
                disabled={disable}
              />
            </div>
            <div
              className={`p-sm-12 p-md-12 ${
                position === FilePosition.RIGHT ? "p-lg-6" : "p-lg-12"
              }`}
              style={{ marginTop: "auto" }}
            >
              <Button
                label={getIntlMessage("CM_BUTTON_LABEL_ADD_ATTACHMENT")}
                disabled={!fileInfo}
                className="p-button-rounded add-attahments-button"
                onClick={(e) => onAddAttachment(e)}
              />
            </div>
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12 attachments-container attachments-holder-div">
            {loading && (
              <div className="af-spinner">
                <Spinner />
              </div>
            )}
            <DataTable className="attachments-table" value={attachments}>
              <Column style={{ width: "60%" }} body={fileContent}></Column>
              <Column style={{ width: "30%" }} field="created_on"></Column>
              <Column style={{ width: "10%" }} body={deleteFileIcon}></Column>
            </DataTable>
          </div>
        </>
      ) : (
        <div className="p-sm-12 p-md-12 p-lg-12">
          <div className="labels-padding">
            <label>{getIntlMessage("CM_LABEL_ATTACHMENTS")}</label>
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12 attachments-container attachments-read-only-holder-div">
            <DataTable className="attachments-table" value={attachments}>
              <Column
                style={{ width: "70%" }}
                body={fileContent}
                field="title"
              ></Column>
              <Column style={{ width: "30%" }} field="created_on"></Column>
            </DataTable>
          </div>
        </div>
      )}
      <NestedConfirmationModal
        headerText={getIntlMessage("ERROR")}
        show={showFileDailog}
        message={
          showFileDailog
            ? getIntlMessage("CM_LABEL_ATTACHMENT_MAX_SIZE")
            : getIntlMessage("MSG_SELECTED_ATTACHMENTS_ARE_TOO_LARGE")
        }
        parentCallback={callBackFromDailog}
        hideNoIcon={true}
        imageSrc="/images/svg/icon_invalid.svg"
      />
      <NestedConfirmationModal
        headerText={getIntlMessage("CM_UNSUPPORTED_FILE_TYPE")}
        show={showFileFormatDailog}
        message={getIntlMessage("CM_FILE_TYPE_NOT_SUPPORTED")}
        parentCallback={() => {
          setShowFileFormatDailog(false);
        }}
        hideNoIcon={true}
      />
      <NestedConfirmationModal
        headerText={getIntlMessage("CONFIRMATION")}
        show={showDeleteConfirmationDailog}
        message={getIntlMessage(
          "CM_LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_ATTACHMENT"
        )}
        parentCallback={callBackDeleteConfirmationDailog}
        yesLabel={getIntlMessage("YES")}
        noLabel={getIntlMessage("NO")}
      />
    </div>
  );
};

export default FileUpload;
