import React from "react";
import { Checkbox } from "primereact/checkbox";
import { Badge } from 'primereact/badge';
import "./CheckBox.scss";

const DraggableCheckBox = ({
  checked,
  id,
  label,
  name,
  onSelectionChange,
  value,
  onDragStart,
  onDragOver,
  onDrop,
  indexValue,
  showOrder = false
}) => {
  return (
    <div
      className="p-field-checkbox draggable-fields"
      onDragOver={(e) => {
        onDragOver(e);
      }}
      onDrop={(e) => onDrop(e, indexValue)}
    >
      <div style={{display: "flex"}}>
        <Checkbox
          inputId={id}
          name={name}
          value={value}
          onChange={(e) => onSelectionChange(e)}
          checked={checked}
          draggable
          onDragStart={(e) => {
            onDragStart(e, value);
          }}
        />
        <label
          htmlFor={id}
          draggable
          onDragStart={(e) => {
            onDragStart(e, value);
          }}
        >
          {label}
        </label></div>
      {showOrder && <Badge value={indexValue+1} />}
    </div>
  );
};

export default DraggableCheckBox;
