let cachedMenuData = null;
let cachedDelegates = null;
let cachedPermissions = null;
let cachedMe = null;
let cachedLiterals = null

export const GetSetMenuData = (value, reset = false) => {
    if (value) {
        cachedMenuData = value;
    }
    cachedMenuData = reset ? null : cachedMenuData;
    return cachedMenuData;
}

export const GetSetDelegateData = (value, reset = false) => {
    if (value) {
        cachedDelegates = value;
    }
    cachedDelegates = reset ? null : cachedDelegates;
    return cachedDelegates;
}


export const GetSetPermissionsData = (value, reset = false) => {
    if (value) {
        cachedPermissions = value;
    }
    cachedPermissions = reset ? null : cachedPermissions;
    return cachedPermissions;
}

export const GetSetMeData = (value, reset = false) => {
    if (value) {
        cachedMe = value;
    }
    cachedMe = reset ? null : cachedMe;
    return cachedMe;
}

export const GetSetLiteralData = (value, reset = false) => {
    if (value) {
        cachedLiterals = value;
    }
    cachedLiterals = reset ? null : cachedLiterals;
    return cachedLiterals;
}