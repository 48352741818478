import React, {useRef} from 'react'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import "../DropDown/DropDownMenu.scss";

function OverLayPanelMenu({
  enableDropDown, content, hidden, icon, onHide
}) {
  const menu = useRef(null);
  return (
    <div className="dropDownMenu">
      <Button
        id='columnselectorbutton'
        className={!enableDropDown ? "enableDropDown" : "disableDropDown"}
        icon={`pi ${icon ? icon : "pi-ellipsis-h"}`}
        onClick={(event) => !enableDropDown && menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
        hidden={hidden}
      />

      <OverlayPanel ref={menu} showCloseIcon={false} style={{ width: '300px' }} onHide={() => onHide()}>
          {content}
        </OverlayPanel>
    </div>
  )
}

export default OverLayPanelMenu