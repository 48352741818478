import React from 'react'
import { Calendar } from 'primereact/calendar';

export default function MonthCalendar({value, onChange}) {
    return (
        <Calendar
            id="monthpicker"
            showIcon={true}
            value={value}
            onChange={(e) => onChange(e.value)}
            view="month"
            dateFormat="mm/yy"
            yearNavigator yearRange="1970:2050" />
    )
}
