const jsUtils = {
    debFlag: null,
    debounce: (doSomething, time, ...params) => {
        if (jsUtils.debFlag) {
            window.clearTimeout(jsUtils.debFlag);
            jsUtils.debFlag = null;
        }
        if (!jsUtils.debFlag) {
            jsUtils.debFlag = window.setTimeout(() => {
                jsUtils.debFlag = null;
                doSomething && doSomething(...params);
            }, time);
        }
    },
    objToQS: (obj) => {
        return Object.keys(obj).reduce((a, b) => {
            return obj[b] ? a + b + "=" + obj[b] + "&" : a;
        }, "");
    },

    sortTable: (data = [], localSort, dataBackup) => {
        if (localSort.propName !== "" && localSort.index !== -1) {
            data = data.sort((a, b) => {
                return localSort.isAsc
                    ? a[localSort.propName] && a[localSort.propName].toString().toLowerCase() >
                        b[localSort.propName] && b[localSort.propName].toString().toLowerCase()
                        ? 1
                        : -1
                    : a[localSort.propName] && a[localSort.propName].toString().toLowerCase() >
                        b[localSort.propName] && b[localSort.propName].toString().toLowerCase()
                        ? -1
                        : 1;
            });
            return JSON.parse(JSON.stringify(data));
        } else {
            return JSON.parse(JSON.stringify(dataBackup));
        }
    },
    updateRowData: (rowId, newRow, data, dataBackup, setHook, setHookBackup) => {
        // takes aray of rows, and updates one row.
        dataBackup = JSON.parse(JSON.stringify(dataBackup));
        dataBackup.forEach((item, index) => {
            if (item.rowId === rowId) {
                dataBackup[index] = newRow;
            }
        });
        data.forEach((item, index) => {
            if (item.rowId === rowId) {
                data[index] = newRow;
            }
        });
        setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
        setHook && setHook(JSON.parse(JSON.stringify(data)));
    },
    deleteRow: (rowId, data, dataBackup, setHook, setHookBackup) => {
        data = data.filter((d) => d.rowId !== rowId);
        dataBackup && (dataBackup = dataBackup.filter((d) => d.rowId !== rowId));
        setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
        setHook && setHook(JSON.parse(JSON.stringify(data)));
    },
    addRow: (rowToAdd, data, dataBackup, setHook, setHookBackup) => {
        data.unshift(rowToAdd);
        dataBackup.unshift(rowToAdd);
        setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
        setHook && setHook(JSON.parse(JSON.stringify(data)));

    },
    editMode: (rowId, editMode, data, dataBackup, setHook, setHookBackup) => {
        data.forEach((item, index) => {
            if (item.rowId === rowId) {
                data[index] = { ...data[index], editMode: editMode };
            }
        });
        dataBackup.forEach((item, index) => {
            if (item.rowId === rowId) {
                dataBackup[index] = { ...dataBackup[index], editMode: editMode };
            }
        })
        setHook && setHook(JSON.parse(JSON.stringify(data)));
        setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    },

    updateACellOrProp: (rowId, propName, newData, data, setHook) => {
        data = JSON.parse(JSON.stringify(data));
        data.forEach((item, index) => {
            if (item.rowId === rowId) {
                item[propName] = newData;
            }
        });
        setHook && setHook(JSON.parse(JSON.stringify(data)));
        // console.log(data);
    },
    getLiteral: (key) => {
        const literals = localStorage?.getItem("_literals");
        if (literals && key && JSON.stringify(literals)[key]) {
            return JSON.parse(literals)[key];
        }
        return key || "";
    },
};
export default jsUtils;
