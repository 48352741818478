import React, { useEffect, useState, useRef } from "react";
import "./UserAndDelegates.scss";
import Avatar from "react-avatar";
import { AutoComplete } from "primereact/autocomplete";
import { Menu } from "primereact/menu";
const PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME = "MG-Practice-MD-TM-FE-AddTime-MngTime";

function UserAndDelegates({ delegateData, timeKeeperFeatureName,
  userDelegateRoundingMethod,
  placeholder,
  showButton,
  timekeeper,
  display,
  timeKeeperListData,
  menuItem,
  onTimeKeeperChange,
  toggleButton,
  setSelectedTimeKeeperStoreAction,
  getCode,
  selectedTimekeeper,
  iconTimekeeper,
  delegateId,
  dateFormat,
  attorneyCode,
  tooltips,
  toolTipkey,
  location,
  setDisplay,
  Redirect,
  dispatchURL,
  userPermission,
  URLforRedirection,
  loggedInUserTK
}) {
  const menu = useRef(null);
  const [filteredTimekeepers, setFilteredTimekeepers] = useState(null);
  useEffect(() => {
    setSelectedTimeKeeperStoreAction(
      delegateId,
      dateFormat,
      attorneyCode,
      userDelegateRoundingMethod,
      timekeeper
    );
  }, [delegateId, dateFormat, attorneyCode, userDelegateRoundingMethod, timekeeper]);
  
  const searchTimekeeper = (event) => {
    setTimeout(() => {
      let filteredTimekeepersList = [];
      timeKeeperListData &&
          timeKeeperListData.forEach((item, index) => {
            if (index !== 0 && ((Array.isArray(timeKeeperFeatureName) && (timeKeeperFeatureName.indexOf(item.featureName)!==-1)) || timeKeeperFeatureName===item.featureName)) {
              if(!filteredTimekeepersList.find(timekeeper=>timekeeper.userDelegateId === item.userDelegateId)) {
                 filteredTimekeepersList.push(item);
              }
            }
          });
          
      if (event.query.trim().length) {
        filteredTimekeepersList= filteredTimekeepersList.filter((_timeKeeper, index) => {
          return _timeKeeper.userDelegateFullName
            .toLowerCase()
            .includes(event.query.toLowerCase());
        });  
      }
      setFilteredTimekeepers(filteredTimekeepersList);
    }, 5);
  };


  const itemTemplate = (item) => {
    return (
      <div className="af-timeKeeper-item" style={{display: 'flex', display: '-webkit-flex'}}>
        <div className="af-client-name af-avatar-list">
          <Avatar
            size="40"
            round={true}
            className="af-avatar-list-items"
            name={item.userDelegateImageS3URL ? null : getUserInitials(item.userDelegateFirstName, item.userDelegateLastName)}
            background="blue"
            src={
              iconTimekeeper === "default"
                ? "/images/svg/TimeKeeperDefaultIconProfile.svg"
                : item.userDelegateImageS3URL
            }
          />
        </div>

        <div className="af-client-name">
          {" "}
          <h6 id="af-dropdown-item-name">{getUserInitials(item.userDelegateFirstName, item.userDelegateLastName)}</h6>
        </div>
      </div>
    );
  };

  const showDeligates = () => {
    document.getElementById("inputBoxTimeKeeper") && document.getElementById("inputBoxTimeKeeper").children[1] && document.getElementById("inputBoxTimeKeeper").children[1].click();
  };

  const getUserInitials = (firstName, LastName) => {
    let initials = "";
        if(!firstName && !LastName){
          initials = "";
        }
        else {
          initials = (firstName || '') + " "+ (LastName || '');
        }
      return initials.trim();
  }

  const showWobo = (pathname) => {
    const screensToHide = ["/", "/landing", "/delegates", "/preferences", "/system/narrativecontrols" , "/system/configuration" , "/access/users" , "/access/roles"]
    if ( pathname.toLowerCase().includes("/analytics")) {
      return false;
    }
    const fIndex = screensToHide.indexOf(pathname.toLowerCase());
    if (fIndex !== -1) {
      return false;
    }
    return true;
  }

 const getPermissionsValueByCriteria= (data, id, fieldname, setting) => {
    return data?.find(x => x.id === id)?.fields?.find(y => y.id === fieldname)?.[setting]
}

 const IsAddTimeIconVisible = (requiredFeatureName) => {
  // when WOBO is selected
   if (selectedTimekeeper) {
     try {
     let userWithFeatureDelegation = delegateData && delegateData.userDelegates
       ?.find(
         (delegateInfo) =>
           delegateInfo.userDelegateId === selectedTimekeeper.userDelegateId && delegateInfo.featureName === requiredFeatureName
       );
      
      if(userWithFeatureDelegation) {
        return userWithFeatureDelegation.timekeeper;
      } else {
        return false;
      }      
    } catch(err) {
      console.log("selected user does not have delegation to this feature : ", requiredFeatureName);
      return false;
    }
       
   } else { // when no WOBO is selected
     return (
       getPermissionsValueByCriteria(
         userPermission,
         "clientmattersgrid",
         "addtime",
         "actionable"
       ) && loggedInUserTK
     );
   } 
 }

  const LocationStore =()=>{
    dispatchURL();
  }

  return (
    <div className="af-user-panel">
      <span className="header-global-shortcut">
      { IsAddTimeIconVisible(PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME) ? <i
      className="icon-icon_global_add_timeentry header-icon-blue"
      onClick={()=>{  LocationStore(); }}
      title="Add Time Entry"
      ></i> : ""}
      </span>
      {showWobo(location.pathname) && timeKeeperFeatureName !== "MG-Account-MD-Autotext-FE-Autotext" && (
        <>
          <div
            className="af-button-delegate"
            style={{ display: showButton ? "" : "none" }}
            onClick={toggleButton}            
          >
            {getCode("WORK_ON_BEHALF_OF") + "... "}
          </div>

          <div className="af-delegates">
            <div className="af-user-img af-delegates-list">
              <Avatar
                size="40"
                style={{ display: !showButton ? "" : "none" }}
                round={true}
                className="af-avatar-delegates-list"
                name={ selectedTimekeeper?.userDelegateImageS3URL ? null :
                  selectedTimekeeper !== null
                    ? getUserInitials(selectedTimekeeper.userDelegateFirstName, selectedTimekeeper.userDelegateLastName)
                    : ""
                }
                src={
                  iconTimekeeper === "default"
                    ? "/images/svg/TimeKeeperDefaultIconProfile.svg"
                    : selectedTimekeeper ? selectedTimekeeper.userDelegateImageS3URL : ""
                }
                onClick={() => {
                  showDeligates();
                }}
              />
            </div>
            <div
              className="af-user-text af-delegates-list"
              style={{ display: !showButton ? "" : "none" }}
            >
              {!showButton && display && showDeligates()}
              <div className="wobo-text"> {getCode("WORKING_ON_BEHALF_OF")} </div>
              <AutoComplete
                id="inputBoxTimeKeeper"
                placeholder={getCode(placeholder)}
                value={selectedTimekeeper}
                onFocus={() => {
                  setDisplay(false);
                }}
                suggestions={filteredTimekeepers}
                completeMethod={searchTimekeeper}
                field="userDelegateFullNameCode"
                dropdown
                itemTemplate={itemTemplate}
                className="auto-complete"
                onSelect={(e) => {
                  onTimeKeeperChange(e.value, true);
                }}
                onContextMenu={() => {
                  showDeligates();
                }}
                onChange={(e) => {
                  onTimeKeeperChange(e.value, false);
                }}
                onClick={() => {
                  setDisplay(false);
                  document
                    .getElementById("inputBoxTimeKeeper")
                    .children[1].click();
                }}
              />
            </div>
            <div
              className=" af-delegates-list"
              style={{ display: !showButton ? "" : "none" }}
            >
              <i className="pi pi-times" onClick={toggleButton}></i>
            </div>
          </div>
        </>
      )}

      <div className="af-user-loggedin">
        <Menu
          model={menuItem}
          popup
          ref={menu}
          id="popup_menu"
          className="pmenulist"
          onClick={() => {
            //console.log(menuItem);
            //history.push(menuItem.url);
          }}
        />
        <Avatar
          size="55"
          round={true}
          title={tooltips[toolTipkey]}
          className="af-avatar-loggedinuser"
          name={delegateData.userDelegates[0].userDelegateImageS3URL ? null : delegateData && 
            delegateData.userDelegates && 
            delegateData.userDelegates.length > 0 ? 
            getUserInitials(delegateData.userDelegates[0].userDelegateFirstName,delegateData.userDelegates[0].userDelegateLastName) 
            : ""}
          src={delegateData.userDelegates && 
            delegateData.userDelegates.length > 0 ? delegateData.userDelegates[0].userDelegateImageS3URL : ""}
          
            onClick={
            (event) => {
              menu.current.toggle(event);
            }
          }
        />
        <img
          alt=""
          className="account-Icon"
          src="/images/iconuserdropdown_u790.svg"
          onClick={
            (event) => {
              menu.current.toggle(event);
            }

          }
          data-testid="accicon"
        />
      </div>
      <div></div>
    </div>
  );
}

export default UserAndDelegates;
