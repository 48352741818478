import React, { useState ,useEffect } from 'react'
import "./TabBarComponent.scss"
import BreadCrumbComp from "../BreadCrumbComp/BreadCrumbComp";
import { DropDownMenu } from "@attorneyflow-mf/styleguide";
import { cloneDeep, debounce } from "lodash";

const TabBarComponent = ({ handleBurgerClick, burgerClicked, tabItems, breadcrumb, Redirect, activeItem, helpurl, getCode, setBurgerClickRetain, customClass = "", moduleName = "" }) => {

  const [customtabItems,setCustomtabItems] = useState([])
  useEffect(()=>{
    setCustomtabItems(tabItems)
  },[tabItems])



let copyiedTabItems = cloneDeep(customtabItems);
let customTabs = copyiedTabItems.length>5 ? copyiedTabItems.slice(0,5) : tabItems

  const tabChange = (evt) => {
    Redirect(evt.path);
  }

  if (burgerClicked) {
    return (
      <div className="tab-container">
        <div className="tab-nav-bar">
          <button
            onClick={handleBurgerClick}
            className="closeButtonBurgerMenu"
          >
            <span className="pi pi-times"></span>
          </button>
        </div>
      </div>
    )
  }
  
  const menuItemValues = [
    {
      label: "Summary",
      showMe: true,
    },
    {
      label: "Status",
      showMe: true,
    },
    {
      label: "Time",
      showMe: true,
     
    },
    {
      label: "Cost",
      showMe: true,
    },
    {
      label: "Bills & Payments",
      showMe: true,
    },
    {
      label: "Invoices",
      showMe: true,
    },
    {
      label: "Manual Invoice",
      showMe: true,
    },
    {
      label: "Payor",
      showMe: true,
    },
    {
      label: "Payor Details",
      showMe: true,
    },
    {
      label: "Invoice Summary",
      showMe: true,
    },
    {
      label: "A/R Management",
      showMe: true,
    },
    {
      label: "Unapplied Cash",
      showMe: true,
    },
    {
      label: "Billing Credits",
      showMe: true,
    },
    {
      label: "Time Rates",
      showMe: true,
    },
    {
      label: "Cost Rates",
      showMe: true,
    },
    {
      label: "Trust Details",
      showMe: true,
    }
  ];
  return (
    <div className={`tab-container ${customClass}`}>
      <div className={`tab-nav-bar ${moduleName}`}>
        <div className="p-grid">
          <div className="p-sm-6 p-md-6 p-lg-4 no-padding">
            <div className="af-common-menu-bar">
              <a
                href={helpurl}
                target="_blank"
              >
                <img
                  title={"Help"}
                  className=""
                  src="/images/svg/Help_icon.svg"
                  alt=""
                />
              </a>
              <div className="burger-icon">
                <i
                  className="pi pi-bars"

                  onClick={handleBurgerClick


                  }
                ></i>
              </div>
              <div className="af-breadcrumb">
                <BreadCrumbComp path={breadcrumb} />

              </div>
            </div>
          </div>
          <div className="p-sm-6 p-md-6 p-lg-8 no-padding">
            <ul className="af-tab-menu">
              {customTabs && customTabs.map((item, i) => { 
                return <li key={i}>            
                  <span
                    className={
                      item?.id === activeItem?.id ? "addClass" : null
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      tabChange(item);
                    }}
                  >
                    {getCode(item.label)}
                  </span>
                </li>
              })}
            </ul>
            {customtabItems?.length > 5 ?
              <div className="client-matter-inquiry">
                <DropDownMenu  
                  enableDropDown={(customtabItems?.length > 5) ? false :true}
                  menuItems={menuItemValues}
                  hidden={customtabItems?.length <= 5}
                />
              </div> : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabBarComponent
