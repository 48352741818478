import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import "./NestedConfirmationModal.scss"
import jsUtils from "../../libs/jsUtils";

const NestedConfirmationModal = ({
    role,
    show,
    message,
    parentCallback,
    headerText = jsUtils.getLiteral('CONFIRMATION'),
    noLabel = jsUtils.getLiteral('CANCEL'),
    yesLabel = jsUtils.getLiteral('OK'),
    imageSrc = "/images/icon_warning.svg",
    noIcon = "pi pi-times",
    yesIcon = "pi pi-check",
    hideNoIcon = false
}) => {

    const [displayConfirmation, setDisplayConfirmation] = useState(show);

    useEffect(() => {
        setDisplayConfirmation(show);
        if (show) {
            const backDrop = window.document.createElement("div");
            backDrop.id = "custom_backdrop";
            document.body.appendChild(backDrop);
        } else {
            const backDropEle = document.getElementById('custom_backdrop');
            if (backDropEle) {
                document.body.removeChild(backDropEle);
            }
        }
    }, [show]);

    const dialogFooter = (
        <div>
            {!hideNoIcon &&
                <Button
                    label={noLabel}
                    icon={noIcon}
                    onClick={(e) => {
                        setDisplayConfirmation(false);
                        parentCallback({ callBackFrom: role, newVal: false });
                    }}
                    className="p-button-text no-button"
                />
            }
            <Button
                label={yesLabel}
                icon={yesIcon}
                onClick={(e) => {
                    parentCallback({ callBackFrom: role, newVal: true });
                    setDisplayConfirmation(false);
                }}
                className="yes-button"
            />
        </div>
    );

    return (
        <div className="nested-confirmation-popup">
            <div className={`p-dialog-mask nested-component-overlay ${displayConfirmation ? 'p-dialog-visible' : ''}`}>
                <div className="p-dialog p-component">
                    <div className="p-dialog-header">
                        <span className="p-dialog-title">{headerText}</span>
                        <div className="p-dialog-header-icons"></div>
                    </div>
                    <div className="p-dialog-content">
                        <div className="confirmation-content">
                            <div>
                                <img title="" alt="" className="icon" src={imageSrc} />
                            </div>
                            <div className="confirmation-msg">
                                {message}
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">
                        <div>
                            {dialogFooter}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NestedConfirmationModal;
