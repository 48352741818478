import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./BreakCrumbComp.scss";
// import commonUtils from "../../../utils/commonUtils";

// const getLabel = (key) => {
//   return commonUtils.getIntlMessage(key)
// };

export default function BreadCrumbComp({ path = [] }) {
  // const items = path.map((pathObj) => {
  //   return { label: getLabel(pathObj) };
  // });
  
  // const home={label: 'Settings'}
  return (
    <div>
      <BreadCrumb model={path} />
    </div>
  );
}
