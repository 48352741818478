import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import Spinner from "../Spinner/Spinner";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import "./DataTable.scss";
import moment from "moment";

const CustomTable = ({
    isLoading,
    noItemsFoundMessage,
    colDef,
    data,
    dataLoaded,
    noItemsFoundMessageText,
    parentMethods,
    onFilterChange,
    onLazyScroll,
    sort,
    onSort,
    enableRowExpansion = true,
    addNewButton,
    closeAllRefresh,
    pageNumber,
    scrollNoMore,
    loadingCard = false,
    rowExpansionElement,
    updateTimerData,
    rowClicked,
    rows,
    moveScrollToTop,
    resetScrollFlag
}) => {

    const [searchParams, setSearchParams] = useState([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
    ]);
    const [rowExpansionId, setRowExpansionId] = useState({});
    const internalRowClicked = (rowItem) => {
        if (!loadingCard && !isLoading && enableRowExpansion) {
            if (!rowExpansionId[rowItem.rowId]) {
                setRowExpansionId({ ...rowExpansionId, [rowItem.rowId]: true });
            } else {
                setRowExpansionId({ ...rowExpansionId, [rowItem.rowId]: false });
            }
        }
    };
    useEffect(() => {
        if (closeAllRefresh) {
            setRowExpansionId({})
        }
    }, [closeAllRefresh])
    useEffect(() => {
        if (moveScrollToTop) {
            bodyScroll.current.scrollTop = 0;
            resetScrollFlag();
        }
    }, [moveScrollToTop])
    useEffect(() => {
        setSearchParams(colDef.map((d) => (d.filter.type === 'date') ? new Date(d.filter?.val) : d.filter?.val));
    }, [colDef]);
    const [filterRefresh] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const resetFilters = () => {
        setSearchParams(["", "", "", "", "", "", ""]);
    };
    let columnClicked = (columnIndex, propName) => {
        if (sort.index === -1) onSort(columnIndex, true, propName);
        else if (sort.index === columnIndex) {
            onSort(columnIndex, !sort.isAsc, propName);
        } else if (sort.index > -1 && sort.index !== columnIndex) {
            onSort(columnIndex, true, propName);
        }
    };

    useEffect(() => {
        if (closeAllRefresh) {
            setExpandedRows({})
        }
    }, [closeAllRefresh])

    const loadFilterElement = (filter, index, dataFilterElement) => {
        switch (filter.type) {
            case "textbox":
                return (
                    <div className={"p-input-icon-right"} style={{ width: '100%' }}><InputText
                        title={filter.title}
                        className={filter.className}
                        placeholder={filter.label}
                        value={searchParams[index]}
                        onChange={(e) => {
                            let state = searchParams;
                            state[index] = e.currentTarget.value;
                            setSearchParams([...state]);
                            onFilterChange(dataFilterElement.propName, e.currentTarget.value, index);
                        }}
                    /><i className="pi pi-search"></i></div>
                );
            case "reset":
                return (
                    <span className="p-column-title">
                        <Button
                            icon="pi pi-times"
                            style={filter.style}
                            className={filter.className}
                            label={filter.label}
                            onClick={() => {
                                filter.type === "reset" && resetFilters();
                                filter.onClick && filter.onClick();
                            }}
                        />
                    </span>
                );

            case "button":
                return (
                    <span className="p-column-title">
                        <Button
                            icon="pi pi-times"
                            style={filter.style}
                            className={filter.className}
                            label={filter.label}
                            onClick={() => {
                                filter.type === "reset" && resetFilters();
                                filter.onClick && filter.onClick();
                            }}
                        />
                    </span>
                );
            case "date":
                return (
                    <div className="calendar-filter">
                        <Calendar
                            appendTo={document.body}
                            tooltip={filter.title}
                            tooltipOptions={{
                                className: "white-tooltip",
                                position: "top",
                                showDelay: 700,
                            }}
                            placeholder="mm/dd/yyyy"
                            showIcon
                            id="filterCalendar"
                            readOnlyInput
                            yearNavigator
                            yearRange="1600:6030"
                            value={searchParams[index]}
                            showButtonBar
                            className="p-column-filter"
                            onChange={(e) =>
                                onFilterChange(
                                    dataFilterElement.propName,
                                    e.value
                                        ? moment(new Date(e.value.toString()).toISOString()).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                        : "",
                                    index
                                )
                            }
                        />
                    </div>
                );
            case "dropdown":
                return (
                    <span>
                        <Dropdown
                            appendTo={document.body}
                            value={filter.val}
                            options={filter.options}
                            onChange={(e) => {
                                onFilterChange(dataFilterElement.propName, e.value, index);
                            }}
                            optionLabel={filter.label}
                            placeholder={filter.placeholder}
                        />
                    </span>
                );
            case "custom":
                return (
                    <span>
                        {filter.renderElement &&
                            filter.renderElement(filter, parentMethods, index)}
                    </span>
                )
            case "switch":
                return (
                    <InputSwitch
                        tooltip={filter.tooltip}
                        tooltipOptions={{
                            className: "white-tooltip",
                            position: "top",
                            showDelay: 700,
                        }}
                        onChange={(e) => {
                            onFilterChange(dataFilterElement.propName, e.value, index);
                        }}
                        disabled={filter.disabled}
                        checked={filterRefresh ? false : filter.val}
                    />
                );
            default:
                return "";
        }
    };

    const loadHeaderElement = (column, index, dataHeaderElement) => {
        switch (column.type) {
            case "string":
                return (
                    <div
                        onClick={() => {
                            columnClicked(index, dataHeaderElement.propName);
                        }}
                    >
                        <span className="p-column-title">{column.label}</span>
                        {column.icon === "present" &&
                            sort.index !== -1 &&
                            index === sort.index ? (
                            <span
                                title="Switch sort order"
                                className={
                                    sort.isAsc
                                        ? "p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                                        : "p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                                }
                            ></span>
                        ) : column.icon === "present" ? (
                            <span
                                title="Switch sort order"
                                className={"p-sortable-column-icon pi pi-fw pi-sort-alt"}
                            ></span>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            case "button":
                return (
                    <span className="af-newbutton">
                        <span className="p-column-title">
                            {column.iconName ?
                                <Button
                                    onClick={() => column.onClick("", "", "")}
                                    label={column.label}
                                    icon={column.iconName}
                                    className={column.className}
                                /> :
                                <div className={"p-input-icon-left"} style={{ width: '100%' }}>
                                    <img src={"/images/svg/icon_w_release.svg"} className={"custom-style-icon-css"}></img>
                                    <Button
                                        onClick={() => column.onClick("", "", "")}
                                        label={column.label}
                                        className={column.className}
                                    />
                                </div>
                            }
                        </span>
                    </span>
                );
            case "checkbox":
                return (
                    <div className="MT-checkbox-div">
                        <span>
                            <input
                                className="MT-checkbox"
                                type="checkbox"
                                checked={column.val}
                                onChange={() => {
                                    column.onClick();
                                }}
                            />
                        </span>
                    </div>
                );
            default:
                return (
                    <span>
                        {column.renderElement &&
                            column.renderElement(column, parentMethods)}
                    </span>
                );
        }
    };

    const loadBodyElement = (rowData, dataBodyElement) => {
        if (dataBodyElement.type === "string") {
            if ((!dataBodyElement.edit || !rowData.editMode) && !rowData.editAll) {
                return rowData[dataBodyElement.propName];
            } else if (dataBodyElement.toggleComp === "custom") {
                return dataBodyElement.renderToggleComp(rowData, parentMethods);
            }
        } else if (dataBodyElement.type === "button") {
            return (
                <Button
                    title={dataBodyElement.tooltip}
                    onClick={(e) => {
                        dataBodyElement.click(rowData);
                    }}
                    className={dataBodyElement.className}
                    label={dataBodyElement.label}
                    icon={dataBodyElement.iconName}
                />
            );
        } else if (dataBodyElement.type === "custom") {
            return dataBodyElement.renderElement(rowData, parentMethods);
        } else {
            return rowData[dataBodyElement.propName];
        }
    };

    const [
        updateComponentonInfinciteScroll,
        setupdateComponentonInfinciteScroll,
    ] = useState(true);
    const onlazyScrollLoad = (event) => {
        setfirst(event.first);
        if (data.length > event.first + event.rows / 2) {
            setupdateComponentonInfinciteScroll(false);
            setupdateComponentonInfinciteScroll(true);
            return null;
        }
        if (scrollNoMore) {
            setupdateComponentonInfinciteScroll(false);
            setupdateComponentonInfinciteScroll(true);
        }
        return onLazyScroll();
    };
    
    const emptyMessage = () => {
        return (
            <div
                style={{
                    position: "absolute",
                    textAlign: "center",
                    top: "225px",
                    // width: "1000px",
                    left: "40%",
                    zIndex: 10,
                }}
            >
                {noItemsFoundMessage && data.length === 0 && (
                    <div className="af-centerAlignNoItemsFound">
                        <div
                            className="af-clientDataNotFound"
                            style={{ width: "100%", textAlign: "left" }}
                        >
                            <div className="icon-col">
                                <img src="/images/svg/icon_sad.svg" alt=""></img>
                            </div>
                            <div className="text-col">
                                <>
                                    <div className="af-empty-error">
                                        {noItemsFoundMessageText
                                            ? noItemsFoundMessageText.titleText
                                            : "Not Found!"}
                                    </div>

                                    {noItemsFoundMessageText.buttonText && (
                                        <div
                                            className={addNewButton ? "timer-disable" : ""}
                                            onClick={colDef[colDef.length - 1].column.onClick}
                                        >
                                            {/* <span className={draftpermission ? 'timer-disable af-newbutton' : 'af-newbutton'}> */}
                                            <span className="af-newbutton">
                                                <span className="p-column-title">
                                                    <Button
                                                        // disabled={draftpermission}
                                                        onClick={() => {
                                                            console.log("Draft Time Entry in datatable");
                                                        }}
                                                        label={
                                                            noItemsFoundMessageText
                                                                ? noItemsFoundMessageText.buttonText
                                                                : "Add"
                                                        }
                                                        icon="pi pi-plus"
                                                        className="p-button-success p-mr-2 time-button af-nodata-class"
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                )}{" "}
            </div>
        );
    };

    const loadingText = () => {
        return <span className="loading-text"></span>;
    };
    const headerDiv = React.useRef(null);
    const bodyScroll = React.useRef(null);
    return (
        <>
            <div ref={headerDiv}
                style={{
                    position: "relative",
                    overflow: 'hidden',
                }}
                className={`p-datatable p-component p-datatable-customers`}>
                <table>
                    <thead className="p-datatable-thead">
                        <tr>
                            {colDef &&
                                colDef.map((headerItems, index) => {
                                    const { data, column } = headerItems;
                                    return (
                                        <th className="p-sortable-column" style={column.style}>{loadHeaderElement(column, index, data)}</th>)
                                })}

                        </tr>
                        <tr>
                            {colDef &&
                                colDef.map((headerItems, index) => {
                                    const { data, column, filter } = headerItems;
                                    return (
                                        <th className={`p-filter-column`} style={column.style}>{loadFilterElement(filter, index, data)}</th>)
                                })}
                        </tr>
                    </thead>
                </table>
            </div>
            <div
                ref={bodyScroll}
                style={{
                    position: "relative",
                    height: '500px',
                    overflowY: 'scroll'
                }}
                className={`p-datatable p-component p-datatable-customers `}
                data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-body"
                onScroll={(e) => {
                    const target = e.target;
                   
                    headerDiv.current.scrollLeft = target.scrollLeft;
                    if (target.scrollHeight - Math.ceil(target.scrollTop) === target.clientHeight) {
                        onLazyScroll();                        
                    }
                    
                }}>
                {isLoading || !dataLoaded ? (
                    <div
                        style={{
                            position: "sticky",
                            width: "100%",
                            top: "50%",
                            height: "0px",
                            zIndex: 10,
                        }}
                    >
                        {isLoading && <Spinner />}
                    </div>
                ) : (
                    emptyMessage()
                )}
                <table>
                    <tbody id="tbody"
                        className="p-datatable-tbody">
                        {
                            data.map((rowItem, index) => {
                                return <React.Fragment>
                                    <tr key={index}
                                        className=""
                                        // key={rowItem.rowId}
                                        onClick={(e) => {
                                            if (e.target.localName === 'td' && e.target.id.startsWith('rowData_')) {
                                                rowClicked && rowClicked(rowItem);
                                                internalRowClicked && internalRowClicked(rowItem);
                                            }
                                        }}>
                                        {colDef.map((col, cIndex) => {
                                            const { data, column } = col;
                                            return <td
                                                id={"rowData_" + cIndex}
                                                style={column.style}
                                                key={cIndex}>
                                                {loadBodyElement(rowItem, data)}

                                            </td>
                                        })}
                                    </tr>
                                    {enableRowExpansion && rowExpansionId[rowItem.rowId] && (
                                        <>
                                            <tr key={index + "s"}></tr>
                                            <tr key={index}>
                                                <td colSpan={colDef.length}>
                                                    {rowExpansionElement && rowExpansionElement(rowItem)}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </React.Fragment>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CustomTable;
