import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import utils from "../../libs/jsUtils";
// import storage from "../../../utils/storage";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./DropDown.scss"
import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import Spinner from "../Spinner/Spinner";
function DropDown({
  columns = [],
  displayType = "text",
  className,
  src,
  textDisplayPropName = "desc",
  placeholder,
  onChange = () => { },
  onEditableChange = () => { },
  listSource = async () => { },
  data = {},
  name,
  resetDropDown,
  disabled = false,
  autoClose = true,
  tabOrder,
  setFocus,
  isEditable = true
}) {
  const containerRef = useRef(null);
  const [writableMode, setWritableMode] = useState(false);
  const [searchText, setSearchText] = useState(data);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noScrollLoad, setNoScrollLoad] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (name === "delegates" && resetDropDown !== 0) {
      setWritableMode(false)
    }
  }, [resetDropDown])

  const loadList = function (searchText = "", page = 1) {
    setLoading(true);
    listSource(searchText, page)
      .then((data) => {
        setList(data);
      })
      .finally(() => setLoading(false));
  };
  const getCode = (key) => {
    return localStorage.getItem("_literals") && JSON.parse(localStorage.getItem("_literals"))[key] ? JSON.parse(localStorage.getItem("_literals"))[key] : key
  };

  const handleSelection = (
    option,
    skipModeChange,
    ignoreIfAlreadySelected = false
  ) => {
    setFocus && setFocus(tabOrder);
    !skipModeChange && setWritableMode(!writableMode);
    if (onChange) {
      onChange(option, option, ignoreIfAlreadySelected);
    }
  };
  const [bottomOfTable, setbottomOfTable] = useState("");
  const showOptionsPopup = () => {
    if (!writableMode) {
      loadList();
    }
    setWritableMode(!writableMode);
    setbottomOfTable("");
    const clickedControlRect = containerRef.current.getBoundingClientRect();
    if (clickedControlRect.top + 222 >= document.body.clientHeight) {
      setbottomOfTable(`${clickedControlRect.bottom - clickedControlRect.top}px`);
    }
  };
  const hideOptionsPopup = () => {
    setWritableMode(!writableMode)
  }

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target) && autoClose) {
      setWritableMode(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("focusout", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("focusout", handleClickOutside);
    }
  }, [])

  return (
    <div className="dropdown-container" ref={containerRef}>
      <div className="p-buttonset af-btn-group ">
        {!writableMode ? (

          <InputText
            autoComplete="off"
            tabIndex={tabOrder}
            onClick={(e) => {
              setSearchText(data);
              !disabled && showOptionsPopup();
            }}
            onMouseUp={(e) => {
              setSearchText(data);
              !disabled && showOptionsPopup();
            }}
            placeholder={placeholder}
            value={data}
            className="p-inputtext p-component p-filled radioButtonInput"
            style={{ width: "100%" }}
            disabled={disabled}
          />
        )
          : (
            <div className="af-input-textbox">
              <InputText
                autoFocus
                autoComplete="off"
                type="text"
                tabIndex={tabOrder}
                className="p-inputtext p-component p-filled radioButtonInput "
                value={searchText}
                placeholder={placeholder}
                onChange={(e) => {
                  isEditable && setSearchText(e.currentTarget.value);
                  // setPage(1);
                  // setShowNoMore(false)
                  // setPage(1);
                  utils.debounce(loadList, 400, e.currentTarget.value);
                  onEditableChange && onEditableChange(e.currentTarget.value);
                }}
                onBlur={(e) => (!list || list?.length === 0) ? hideOptionsPopup() : null}
                disabled={disabled}
              />
              <div

                className={className} style={bottomOfTable ? { bottom: bottomOfTable}: {}}
              >
                <table>
                  <thead className="thead-addtime">
                    <tr>
                      {columns.map((d, index) => (
                        <td
                          key={index}
                          className="radio-options-table-headercode"
                        >
                          {d}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {list && Array.isArray(list) &&
                      list.map((option, index) => {
                        return (
                          option.default ?
                            <tr
                              key={index}
                              onClick={(e) => {
                                handleSelection(option, false, true);
                              }}
                              className="radio-options-table-row"
                            >
                              <td style={{ wordBreak: "break-word" }}>
                                {option.label}
                              </td>
                            </tr> :
                            <tr
                              key={index}
                              onClick={(e) => {
                                handleSelection(option, false, true);
                              }}
                              className="radio-options-table-row"
                            >
                              <td>
                                {getCode(option.featureKey) || option.email}
                              </td>
                              <td>
                                {option.lastName && option.firstName && option.lastName + ", " + option.firstName}
                                {option.featureValue && option.featureValue}
                              </td>
                            </tr>

                        );
                      })}
                    {loading && (
                      <tr>
                        {" "}
                        <td colSpan={2}>
                          <div className="af-spinner">
                            {" "}
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        {listSource && (
          <img
            className={`af-icon-search ${disabled ? 'img-disabled' : ''}`}
            alt=""
            src={src}
            onClick={(e) => {
              setSearchText(data);
              showOptionsPopup();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DropDown;
