import React from 'react'
import "./Menuitems.scss"

const Menuitems = ({itemdata, getCode, handlesignout,Redirect}) => {
  
    return (
      <div className="burgerMenuContainer p-grid">
        {itemdata.moduleGroups  &&
          itemdata.moduleGroups.map(
            (moduleGroupObj) => {
              if (moduleGroupObj.modules.length > 0) {
                return (
                  <div className="moduleGroup p-sm-4 p-md-4 p-lg-2" key={moduleGroupObj.moduleGroupID}>
                    <div className="moduleGroupHeading">
                      <h2 className="moduleGroupHeadingName">
                        <span>
                          <img
                            alt=""
                            src={moduleGroupObj.moduleGroupSmallIconURL.replace(
                              "S3://AttorneyFlow/icons/",
                              "/images/landing/"
                            )}
                            className="moduleGroupHeadingIcon"
                          />
                        </span>{" "}
                        {getCode(moduleGroupObj.moduleGroupName)}
                      </h2>
                    </div>
                    <ul className="modulesContainer">
                      {moduleGroupObj.modules.map((moduleObj) => {
                        if (moduleObj.moduleName === "Logout") {
                          return (
                            <li className="moduleHeading"
                               key={moduleGroupObj.modules.moduleID}
                            >
                              <span
                                onClick={() => handlesignout()}
                              >
                                {getCode(moduleObj.moduleName)}
                              </span>
                            </li>
                          );
                        } else {
                          return (
                            <li className="moduleHeading"
                             key={moduleGroupObj.modules.moduleID}
                            >
                              <span
                                onClick={() => Redirect(moduleObj.url, moduleObj.moduleGroupName, moduleObj.moduleName)}
                              >
                                {getCode(moduleObj.moduleName)}
                              </span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                );
              }
              return null;
            }
          )
        }
        </div>
          )    
}

export default Menuitems
