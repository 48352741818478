import React from "react";
import { Checkbox } from "primereact/checkbox";
import "./CheckBox.scss";

const CheckBox = ({ checked, id, label, name, onSelectionChange, value ,  onKeyPress =() =>{}, tabOrder =0 }) => {
  return (
    <div className="p-field-checkbox">
      <Checkbox
        inputId={id}
        name={name}
        value={value}
        onChange={(e) => onSelectionChange(e)}
       
        onKeyPress={onKeyPress}
        checked={checked}
        tabIndex={tabOrder}
      />
     {label && <label htmlFor={id}>{label}</label>} 
    </div>
  );
};

export default CheckBox;
