import React from "react";
import { MultiSelect } from "primereact/multiselect";

const MultiSelectPrime = ({filterBy, value, options, onChange, onFocus, optionLabel, itemTemplate }) => {
    return (       
                <MultiSelect
                    inputId="multiselect"
                    value={value}
                    options={options}
                    onChange={onChange}
                    onFocus={onFocus}
                    optionLabel={optionLabel}
                    filter
                    filterBy={filterBy}
                    itemTemplate={itemTemplate}
                />
    );
};

export default MultiSelectPrime;
