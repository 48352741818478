import React from "react";
import { Dropdown } from "primereact/dropdown";
import "./DropDown.scss";

function DropdownPrime({
    value,
    options,
    onChange,
    optionLabel,
    placeholder,
    filterInputAutoFocus
}) {
    

    return (
        <Dropdown
            appendTo={document.body}
            value={value}
            options={options}
            onChange={onChange}
            optionLabel={optionLabel}
            placeholder={placeholder}
            filter
            filterInputAutoFocus={filterInputAutoFocus}
        />
    );
        
}

export default DropdownPrime;
